<template>
<div>
    <generated-workout />
</div>
</template>

<script>

import GeneratedWorkout from "@/components/GeneratedWorkoutList.vue";

export default {
  components: {
 
    GeneratedWorkout
  }
};
</script>

<style>

</style>