<template>
  <display-all-users />
</template>

<script>
import DisplayAllUsers from "@/components/DisplayAllUsers.vue";

export default {
    components: {
    DisplayAllUsers
    }
}
</script>

<style>

</style>