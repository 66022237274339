<template>
  <display-exercise-list />
</template>

<script>
import DisplayExerciseList from '@/components/DisplayExerciseList.vue'

export default {
  components: { DisplayExerciseList },

}
</script>

<style>

</style>