<template>
  <!-- <bio/> -->
  <div class="container">
    <div class="row mt-4 row justify-content-center">
      <div class="col-md-6 col-xs-12  px-0">
        <div class="row bio mx-2">
          <div class="col-xs-12 col-lg-12">
            <h3 class="display-5 font-weight-bold text-center">
              Jaclyn Spangler
            </h3>
          </div>

          <div class="col-xs-12 col-lg-6 mt-4 my-auto text-left">
            <p>
              Jaclyn, aka<span style="color: #5e62d1; font-weight: bold">
                "Cardio Queen"</span
              >
              knows there is more to cardio than just hitting the treadmill or
              Stairmaster. Jaclyn's workouts are fun, intense, and target
              multiple major muscle groups to strengthen them while working on
              cardio. She has been with the Git Fit family for 6 years and loves
              pushing people to be their best.
            </p>
          </div>
          <div class="col-xs-12 col-lg-6 align-self-center">
            <img
              src="images/trainer/jaclyn.jpg"
              alt="Jaclyn Trainer"
              class="img-responsive img-fluid my-4"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12  px-0">
        <div class="row bio mx-2">
          <div class="col-xs-12 col-md-12">
            <h3 class="display-5 font-weight-bold text-center">
              John Baringhaus
            </h3>
          </div>

          <div class="col-xs-12 col-lg-6 mt-4 my-auto text-left">
            <p>
              John<span style="color: #5e62d1; font-weight: bold">
                "The Chest Crusader"</span
              >has a background from training professional and amateur athletes
              to working professionals with nominal experience in fitness. John
              approaches fitness as a life long behavior where we have
              continuous improvement. He defines his sessions as simple and
              challenging.
            </p>
          </div>
          <div class="col-xs-12 col-lg-6 align-self-center">
            <img
              src="images/trainer/john.jpg"
              alt="John Trainer"
              class="img-responsive img-fluid my-4"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12  px-0">
        <div class="row bio mx-2">
          <div class="col-xs-12 col-md-12">
            <h3 class="display-5 font-weight-bold text-center">
              Zack Sempsrott
            </h3>
          </div>

          <div class="col-xs-12 col-lg-6 mt-4 my-auto text-left">
            <p>
              Zack believes in working out with an intense "no pain, no gain"
              mindset. Though he stands behind the importance of working the
              full body, he was given the nickname
              <span style="color: #5e62d1; font-weight: bold">
                "The Arms Assassin"</span
              >
              when he started working his biceps in the fourth grade. If you
              need a trainer who will hold you accountable and push you to your
              limits, Zack's your guy.
            </p>
          </div>
          <div class="col-xs-12 col-lg-6 align-self-center">
            <img
              src="images/trainer/zack.jpg"
              alt="Zack Trainer"
              class="img-responsive img-fluid my-4"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12  px-0">
        <div class="row bio mx-2">
          <div class="col-xs-12 col-md-12">
            <h3 class="display-5 font-weight-bold text-center">
              Jamal Stephenson
            </h3>
          </div>

          <div class="col-xs-12 col-lg-6 mt-4 my-auto text-left">
            <p>
              "Mamba Mentality, A constant quest to be the best version of
              yourself". That is all I ask, yes it will be hard, no you can't
              take a break, and yes you will get great results. When choosing me
              as your trainer not only do you get the intensity of a former D1
              athlete, you'll also recieve nutritional guidance. Known as
              <span style="color: #5e62d1; font-weight: bold">
                "The Back Behemoth"</span
              >, you'll find out why.
            </p>
          </div>
          <div class="col-xs-12 col-lg-6 align-self-center">
            <img
              src="images/trainer/jamal.jpg"
              alt="Jamal Trainer"
              class="img-responsive img-fluid my-4"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12 px-0">
        <div class="row bio mx-2">
          <div class="col-xs-12 col-md-12">
            <h3 class="display-5 font-weight-bold text-center">
              Domenic Ceccoli
            </h3>
          </div>

          <div class="col-xs-12 col-lg-6 mt-4 my-auto text-left">
            <p>
              Domenic has been a lifetime advocate of core strength earning him
              the nickname
              <span style="color: #5e62d1; font-weight: bold">
                "The Abs Ambassador"</span
              >. Domenic believes that core strength is the baseline of physical
          fitness, and full body fitness stems from a solid core. Are you
          looking to get ripped?
          Dom is the perfect trainer for you! 
            </p>
          </div>
          <div class="col-xs-12 col-lg-6 align-self-center">
            <img
              src="images/trainer/dom.jpg"
              alt="Dom Trainer"
              class="img-responsive img-fluid my-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
      <h3>Jamal Stephenson</h3>
      <div class="bio">
        <img src="images/trainer/jamal.jpg" alt="Jamal" />
        <p>
          "Mamba Mentality, A constant quest to be the best version of
          yourself". As a trainer that is all I ask, yes it will be hard, no you
          can not take a break, and yes you will get great results. Come to the
          gym to see friends, hang out, and take selfies? Sorry not the trainer
          for you (selfies are for post workout to admire gains). You can not
          out work a bad diet (I've tried!) when choosing myself as your trainer
          not only do you get the intensity of a former D1 athlete you also
          recieve the nutritional guidance to upgrade to you 2.0! Known around
          these parts as
          <span style="color: #5e62d1; font-weight: bold"
            >"The Back Behemoth"</span
          >, you'll find out why
        </p>
      </div>
      <h3>Domenic Ceccoli</h3>
      <div class="bio">
        <img src="images/trainer/dom.jpg" alt="Dom Trainer" />
        <p>
          Domenic has been a lifetime advocate of core strength, and his ab
          workout routines have taken him all over the world, earning him the
          nickname
          <span style="color: #5e62d1; font-weight: bold"
            >"The Abs Ambassador"</span
          >. Domenic believes that core strength is the baseline of physical
          fitness, and full body fitness stems from a solid core. Are you
          looking to get ripped, 6 pack abs for Hot Boy (or Girl) Summer? If so,
          Dom just might be the perfect trainer for you! Come see why numerous
          Hollywood actors and actresses have come to Dom before in preparation
          for their blockbuster films!
        </p>
      </div>
    </div> -->
</template>

<script>
import bioService from "@/services/BioService";
export default {
  component: bioService,
};
</script>

<style>
.bio {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
  border: 2px solid #5e62d1;
}

.bio img {
  border-radius: 999px;
  margin: 0 10px;
}

.bio:nth-child(2n) img {
  /*display: flex;*/
  order: 2;
}
</style>
