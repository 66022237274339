<template>
    <add-exercise />
</template>

<script>
import AddExercise from "@/components/AddExercise.vue";
//import EditExercise from "@/components/AddExercise.vue";
export default {
  components: {
    AddExercise
  },
};
</script>

<style>

</style>