<template>
  <display-user-details />
</template>

<script>
import DisplayUserDetails from "@/components/DisplayUserDetails.vue"
export default {
    components: {
        DisplayUserDetails
    }

}
</script>

<style>

</style>