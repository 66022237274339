<template>
  <display-history />
</template>

<script>
import DisplayHistory from "@/components/DisplayHistory.vue";

export default {
    components: {
        DisplayHistory
    }

}
</script>

<style>

</style>