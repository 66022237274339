<template>
    <bio />
</template>

<script>
import Bio from "@/components/DisplayBio.vue";
export default {  
  components: {
    Bio 
  },
};
</script>

<style>

</style>