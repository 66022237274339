<template>
<div>
    <display-workout-list />
    
</div>
</template>

<script>
import DisplayWorkoutList from "@/components/DisplayWorkoutList.vue"; 



export default {
  components: {
    DisplayWorkoutList
    
  }
};
</script>

<style>

</style>