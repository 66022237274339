<template>
<exercise-details />
  
</template>

<script>
import ExerciseDetails from '@/components/ExerciseDetails.vue'

export default {
    components: {
        ExerciseDetails
    }


};
</script>

<style>

</style>