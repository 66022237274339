<template>
  <view-saved-work-details />
</template>

<script>

import ViewSavedWorkDetails from '@/components/ViewSavedWorkDetails.vue';
export default {  
  components: {
    ViewSavedWorkDetails
  },
};
</script>

<style>

</style>